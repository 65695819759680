import React from "react";
import poliradioLogo from "./assets/LOGO NERO.svg"; // Poliradio logo
import './App.css'; // Import the CSS file

const App = () => {
    return (
        <div className="container">
            <div className="overlay">
                <a href="https://www.poliradio.it">
                    <img src={poliradioLogo} alt="Poliradio Logo" className="gif-logo"/>
                </a>
                <div className="title-container">
                    <h1 className="title1">Not AM</h1>
                    <p className="text-lg italic font-poppins mt-2">(not AM, not FM)</p>
                    <h1 className="title2">Not FM</h1>
                </div>
                <div>
                    <h2 className="event-title">Welcome Back Party</h2>
                    <div className="event-details">
                        <p>
                            <strong>Where:</strong> Giardini del Rettorato
                        </p>
                        <p>
                            <strong>When:</strong> 25 Ottobre
                        </p>
                        <p>
                            <strong>Time:</strong> Dalle 18
                        </p>
                    </div>
                    <div className="event-promo">
                        <p>
                            POLI.RADIO torna per un’altra serata all’insegna della musica! Per iniziare l'anno accademico
                            venite a ballare con noi alla <strong>Not Am Not Fm Welcome Back</strong> ai giardini del rettorato!
                        </p>
                        <p>
                            Non saranno serviti cibi e bevande.
                        </p>
                    </div>
                </div>
                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3627.970587418922!2d9.227612387851376!3d45.478564137426694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6f3e3fb0cfd%3A0x44261104d36bdb9e!2sPiazza%20Leonardo%20da%20Vinci%2C%2054%2C%2020133%20Milano%20MI!5e0!3m2!1sit!2sit!4v1727950572556!5m2!1sit!2sit"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="event-map"
                    >
                    </iframe>
                </div>
                <div className="footer">Iniziativa realizzata con il contributo del Politecnico di Milano.</div>
            </div>
        </div>
    );
};

export default App;
